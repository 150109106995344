import { useCallback, useState } from 'react'
import { callGraphQlClient } from '@/functions/loaderGraphQl'
import { getUserAddresses } from '@/graphql/query/user/getUserAddressesQuery'
import { useDispatch, useSelector } from 'react-redux'
import { orderIdSelector, userDataSelector } from '@/selectors/selectors'
import { getUserGuestToken } from '@/graphql/client'
import { transferOrderMutation } from '@/graphql/mutation/user/transferOrderMutation'
import { setCookies } from 'cookies-next'
import { useRouter } from 'next/router'
import { resolveAddressSuggestionsOnBranch } from '@/graphql/query/resolveAddressSuqestionsOnBranch'
import { showError } from '@/functions/ui/toastify'
import { changeDeliveryData, transferOrder } from '@/functions/orderControl/orderControl'
import { BRAND_ID, WarePriceTypeEnum } from '@/constants/constants'
import { resolveAddressSuggestions } from '@/graphql/query/resolveAddressSuqestions'

export function userRouterHook() {
    const router = useRouter()

    const setupReturnPage = useCallback(() => {
        let params = '/'
        if (router.query.returnTo) {
            params = router.query.returnTo
        } else {
            if (router.query.branch) params += router.query.branch + '/'
            if (router.query.category) params += router.query.category + '/'
            if (router.query.id) params += `detail?id=${router.query.id}/`
            if (router.pathname.includes('kosik')) params += 'kosik'
            if (router.pathname.includes('placeni')) params += 'placeni'
            if (router.pathname.includes('dekujeme')) {
                params += 'dekujeme'
                if (router.query.orderId) params += '?orderId=' + router.query.orderId
                if (router.query.userToken) params += '&userToken=' + router.query.userToken
            }
            if (router.pathname.includes('ucet/prehled')) params += 'ucet/prehled'
            if (router.pathname.includes('ucet/objednavky')) params += 'ucet/objednavky'
            if (router.pathname.includes('ucet/kredity')) params += 'ucet/kredity'
            if (router.pathname.includes('ucet/nastaveni')) params += 'ucet/nastaveni'
        }
        return params
    }, [router])

    const redirectUserBackToOrder = useCallback(() => {
        router.push(`/${router.query.returnTo}`)
    }, [router])

    function redirectToRegistration(router, step = 1) {
        router.push({ pathname: '/ucet/registrace', query: { step: step, returnTo: setupReturnPage() } })
    }

    function redirectToPasswordRest(router, step = 1, email) {
        router.push({ pathname: '/ucet/heslo', query: { step: step, email: email, returnTo: setupReturnPage() } })
    }

    function redirectToLogin(router) {
        router.push({ pathname: '/ucet/prihlaseni', query: { returnTo: setupReturnPage() } })
    }
    function redirectToOverview(router) {
        router.push({ pathname: '/ucet/prehled', query: { returnTo: setupReturnPage() } })
    }

    function redirectToOrders(router) {
        router.push({ pathname: '/ucet/objednavky', query: { returnTo: setupReturnPage() } })
    }
    function redirectToCredit(router) {
        router.push({ pathname: '/ucet/kredit', query: { returnTo: setupReturnPage() } })
    }
    function redirectToSettings(router) {
        router.push({ pathname: '/ucet/nastaveni', query: { returnTo: setupReturnPage() } })
    }

    return {
        redirectToLogin,
        redirectToRegistration,
        redirectToPasswordRest,
        redirectToOverview,
        setupReturnPage,
        redirectUserBackToOrder,
        redirectToOrders,
        redirectToCredit,
        redirectToSettings
    }
}

export function userAddressesHook() {
    const userData = useSelector(userDataSelector)
    const dispatch = useDispatch()

    const [userAddresses, setUserAddresses] = useState(null)

    function fetchUserAddresses() {
        console.debug('test')
        if (userData.id) {
            const variables = {
                id: parseInt(userData.id)
            }
            callGraphQlClient(getUserAddresses, variables, dispatch).then(res => {
                if (res) {
                    setUserAddresses(res.user.addresses)
                }
            })
        }
        setUserAddresses(null)
    }

    const validateAddressOnBranch = useCallback((address, branchId, orderId, setFinalAddress = null) => {
        if (branchId) {
            const variables = {
                gps: {
                    latitude: address.gps.latitude,
                    longitude: address.gps.longitude
                },

                companyBranchId: branchId
            }
            callGraphQlClient(resolveAddressSuggestionsOnBranch, variables, dispatch).then(res => {
                if (res && res.suggestions && res.suggestions.length !== 0) {
                    const resolveLocation = res.suggestions[0]
                    if (resolveLocation) {
                        if (!resolveLocation.inDeliveryLocation) showError('Bohužel adresa není v rozvozové zóně.')
                        else {
                            const data = {
                                resolveLocation: {
                                    ...resolveLocation
                                },
                                unFormattedAddress: ''
                            }
                            console.debug(resolveLocation.companyBranch)
                            if (orderId) {
                                changeDeliveryData(dispatch, data)
                                transferOrder(
                                    orderId,
                                    resolveLocation.companyBranch.id,
                                    resolveLocation.gpsCoordinates,
                                    '',
                                    WarePriceTypeEnum.DELIVERY,
                                    dispatch
                                )
                            }
                        }
                    }
                }
            })
        } else {
            const variables = {
                gps: {
                    latitude: address.gps.latitude,
                    longitude: address.gps.longitude
                },

                brandId: BRAND_ID
            }

            return callGraphQlClient(resolveAddressSuggestions, variables, dispatch)
                .then(res => {
                    if (res && res.suggestions && res.suggestions.length !== 0) {
                        const resolveLocation = res.suggestions[0]
                        if (!resolveLocation.inDeliveryLocation) {
                            showError('Bohužel adresa není v rozvozové zóně žádné z poboček.')

                            setFinalAddress({
                                error: true,
                                message: 'Bohužel adresa není v rozvozové zóně žádné z poboček.',
                                resolveLocation: resolveLocation,
                                unFormattedAddress: ''
                            })
                        } else {
                            setFinalAddress({
                                error: false,
                                message: '',
                                resolveLocation: resolveLocation,
                                unFormattedAddress: ''
                            })
                        }
                    }
                })
                .catch(() => {})
        }
    }, [])

    return { fetchUserAddresses, userAddresses, validateAddressOnBranch }
}

export function transferOrderHook() {
    const dispatch = useDispatch()
    const orderId = useSelector(orderIdSelector)

    async function transferUserOrder(toGuest, userToken) {
        let token = null
        if (toGuest) {
            token = await getUserGuestToken()
        } else {
            token = userToken
        }
        const variables = {
            orderId: orderId,
            token: token
        }

        if (token && orderId)
            return callGraphQlClient(transferOrderMutation, variables, dispatch)
                .then(() => {
                    setCookies('token', token, { secure: false })
                    return true
                })
                .catch(() => {
                    return false
                })
        else {
            setCookies('token', token, { secure: false })
            return true
        }
    }
    return { transferUserOrder }
}
